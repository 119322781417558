@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  box-sizing: border-box;
}

body {
  cursor: url("../public/images/cursor.svg"), crosshair;
}

.projectFont {
  font-family: "Montserrat", sans-serif;
}

body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
  width: 100%;
}

/* header links animated hover effects */
header {
  transition: background-color 0.3s ease, color 0.3s ease;
}

header .navbar a {
  text-decoration: none;
  position: relative;
}

header .navbar a {
  line-height: 2;
  position: relative;
  font-size: 15px;
}

header .navbar a::before {
  content: "";
  width: 0;
  height: 2px;
  border-radius: 2px;
  background-color: #e31b6d;
  position: absolute;
  bottom: -0.25rem;
  right: 0;
  transition: right 0.4s, width 0.4s, left 0.4s;
}

header .navbar a:hover::before {
  width: 100%;
  left: 0;
}

/* card */

.project-card-overlay {
  cursor: pointer;
  width: 0;
  background-color: transparent;
  transition: width 0.4s ease-in-out, background-color 0.4s ease-in-out,
    z-index 0.4s ease-in-out 0.3s;
  z-index: 1; /* Set the initial z-index value */
}

.project-card:hover .project-card-overlay {
  background-color: #e31b6d;
  z-index: -10;
  width: 100%;
  transition-delay: 0s; /* Reset the transition delay */
}

/* css loaders */

.loader {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.loader::after {
  content: "";
  width: 96px;
  height: 4.8px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: hitZak 0.6s ease-in-out infinite alternate;
}

@keyframes hitZak {
  0% {
    left: 0;
    transform: translateX(-1%);
  }
  100% {
    left: 100%;
    transform: translateX(-99%);
  }
}

/* hamburger menu */

/* hamburger menu */

.menu-container {
  color: white;
}

.menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.menu-slide-enter {
  transform: translateX(100%);
}

.menu-slide-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
}

.menu-slide-exit {
  transform: translateX(0%);
}

.menu-slide-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.menu {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
}

.menu ul li a {
  font-size: 20px;
}

/* tabbing */
.tabs {
  display: flex;
  margin: auto;
  column-gap: 20px;
  margin-bottom: 1rem;
  width: 50%;
}

.tab {
  flex: 1;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: border-bottom 0.3s ease-in-out;
}

.tab.active {
  border-bottom: 2px solid #3498db; /* Blue border for active tab */
}

.content {
  overflow: hidden;
}

.tab-content {
  padding: 1rem;
}

/* Fade in and fade out effect on content change */
.fade-enter,
.fade-exit {
  opacity: 0.5;
}

/* Fade in active content and fade out inactive content */
.fade-enter-active,
.fade-exit-active {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.glass-effect {
  /* From https://css.glass */
  background-color: rgba(0, 0, 0, 0.6);
  /* background: orange; */
  /* background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3); */
  /* background-image: linear-gradient(to bottom right, #efa2b4, #aef1ee); */
}

.glass-effect-second {
  opacity: 1;
  /* From https://css.glass */
  background: white;
  /* background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3); */
}

/* modal button css */

.modal-button::before {
  content: "";
  background-color: rgb(248, 50, 93);
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 200ms ease-in-out;
  display: inline-block;
}

.modal-button:hover::before,
.modal-button .iconCross {
  color: white;
}

.modal-button:hover::before {
  width: 100%;
}

/*  */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  z-index: 100;
  justify-content: center;
  overflow-y: auto; /* Enable vertical scrolling within the modal if the content overflows */
}

.modal-container {
  max-width: 80%; /* Adjust the maximum width of the modal container as needed */
  width: 100%;
  background-color: white; /* Set the background color of the modal */
  border-radius: 8px;
  overflow: hidden; /* Hide any content overflowing the modal container */
}

.modal-container.open {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add any additional styling you need */

/* button */
.sparkle-button {
  --active: 0;
  --bg: radial-gradient(
      40% 50% at center 100%,
      hsl(270 calc(var(--active) * 97%) 72% / var(--active)),
      transparent
    ),
    radial-gradient(
      80% 100% at center 120%,
      hsl(260 calc(var(--active) * 97%) 70% / var(--active)),
      transparent
    ),
    hsl(260 calc(var(--active) * 97%) calc((var(--active) * 44%) + 12%));
  background: var(--bg);
  font-size: 15px;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  padding: 7px 10px 7px 10px;
  display: flex;
  align-items: center;
  gap: 0.25em;
  white-space: nowrap;
  border-radius: 100px;
  position: relative;
  box-shadow: 0 0 calc(var(--active) * 3em) calc(var(--active) * 1em)
      hsl(260 97% 61% / 0.75),
    0 0em 0 0
      hsl(260 calc(var(--active) * 97%) calc((var(--active) * 50%) + 30%)) inset,
    0 -0.05em 0 0 hsl(260 calc(var(--active) * 97%) calc(var(--active) * 60%)) inset;
  transition: box-shadow var(--transition), scale var(--transition),
    background var(--transition);
  scale: calc(1 + (var(--active) * 0.1));
  transition: 0.3s;
}

.sparkle-button:active {
  scale: 1;
  transition: 0.3s;
}

.sparkle path {
  color: hsl(0 0% calc((var(--active, 0) * 70%) + var(--base)));
  transform-box: fill-box;
  transform-origin: center;
  fill: currentColor;
  stroke: currentColor;
  animation-delay: calc((var(--transition) * 1.5) + (var(--delay) * 1s));
  animation-duration: 0.6s;
  transition: color var(--transition);
}

.sparkle-button:is(:hover, :focus-visible) path {
  animation-name: bounce;
}

@keyframes bounce {
  35%,
  65% {
    scale: var(--scale);
  }
}

.sparkle path:nth-of-type(1) {
  --scale: 0.5;
  --delay: 0.1;
  --base: 40%;
}

.sparkle path:nth-of-type(2) {
  --scale: 1.5;
  --delay: 0.2;
  --base: 20%;
}

.sparkle path:nth-of-type(3) {
  --scale: 2.5;
  --delay: 0.35;
  --base: 30%;
}

.sparkle-button:before {
  content: "";
  position: absolute;
  inset: -0.2em;
  z-index: -1;
  border: 0.25em solid hsl(260 97% 50% / 0.5);
  border-radius: 100px;
  opacity: var(--active, 0);
  transition: opacity var(--transition);
}

.spark {
  position: absolute;
  inset: 0;
  border-radius: 100px;
  rotate: 0deg;
  overflow: hidden;
  mask: linear-gradient(white, transparent 50%);
  animation: flip calc(var(--spark) * 2) infinite steps(2, end);
}

@keyframes flip {
  to {
    rotate: 360deg;
  }
}

.spark:before {
  content: "";
  position: absolute;
  width: 200%;
  aspect-ratio: 1;
  top: 0%;
  left: 50%;
  z-index: -1;
  translate: -50% -15%;
  rotate: 0;
  transform: rotate(-90deg);
  opacity: calc((var(--active)) + 0.4);
  background: conic-gradient(from 0deg, transparent 0 340deg, white 360deg);
  transition: opacity var(--transition);
  animation: rotate var(--spark) linear infinite both;
}

.spark:after {
  content: "";
  position: absolute;
  inset: var(--cut);
  border-radius: 100px;
}

.backdrop {
  position: absolute;
  inset: var(--cut);
  background: var(--bg);
  border-radius: 100px;
  transition: background var(--transition);
}

@keyframes rotate {
  to {
    transform: rotate(90deg);
  }
}

@supports (selector(:has(:is(+ *)))) {
  body:has(button:is(:hover, :focus-visible)) {
    --active: 1;
    --play-state: running;
  }

  .bodydrop {
    display: none;
  }
}

.sparkle-button:is(:hover, :focus-visible) ~ :is(.bodydrop, .particle-pen) {
  --active: 1;
  --play-state: runnin;
}

.sparkle-button:is(:hover, :focus-visible) {
  --active: 1;
  --play-state: running;
}

.sp {
  position: relative;
}

.particle-pen {
  position: absolute;
  width: 200%;
  aspect-ratio: 1;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  -webkit-mask: radial-gradient(white, transparent 65%);
  z-index: -1;
  opacity: var(--active, 0);
  transition: opacity var(--transition);
}

.particle {
  fill: white;
  width: calc(var(--size, 0.25) * 1rem);
  aspect-ratio: 1;
  position: absolute;
  top: calc(var(--y) * 1%);
  left: calc(var(--x) * 1%);
  opacity: var(--alpha, 1);
  animation: float-out calc(var(--duration, 1) * 1s) calc(var(--delay) * -1s)
    infinite linear;
  transform-origin: var(--origin-x, 1000%) var(--origin-y, 1000%);
  z-index: -1;
  animation-play-state: var(--play-state, paused);
}

.particle path {
  fill: hsl(0 0% 90%);
  stroke: none;
}

.particle:nth-of-type(even) {
  animation-direction: reverse;
}

@keyframes float-out {
  to {
    rotate: 360deg;
  }
}

.text {
  translate: 2% -6%;
  letter-spacing: 0.01ch;
  background: linear-gradient(
    90deg,
    hsl(0 0% calc((var(--active) * 100%) + 65%)),
    hsl(0 0% calc((var(--active) * 100%) + 26%))
  );
  -webkit-background-clip: text;
  color: transparent;
  transition: background var(--transition);
}

.sparkle-button svg {
  inline-size: 1.25em;
  translate: -25% -5%;
}

/* honors card css */
.honors {
  width: 27rem;
  background: #292929;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 1), 0 5px 15px 0 rgba(0, 0, 0, 1);
  border-radius: 15px;
  padding: 10px 15px;
  margin: 20px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  min-height: 300px;
  transition: 0.7s;
}

/* hover gallery */
/* Add this to your styles or import a separate stylesheet */
.plus-icon-parent {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease; /* Apply transition to transform property */
}

.plus-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
}

.plus-icon-parent:hover .plus-icon {
  transform: translate(-50%, -50%) scale(1);
}

.plus-icon-parent:hover {
  transform: scale(1.05); /* Apply a slight scale-up effect on hover */
}

/* Rest of your existing styles */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add more styles for your modal content as needed */
